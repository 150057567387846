import './structural.css'

const VarPage = (props) => {
	return (
		<div className={"varpage " + props.className} id={props.id}>
			{ props.children }
		</div>
	)
}

const Page = (props) => {
	return (
		<div className={"page " + props.className} id={props.id}>
			{ props.children }
		</div>
	)
}

export { VarPage, Page };
