import { RiHome5Fill } from "react-icons/ri";
import { FaBasketShopping } from "react-icons/fa6";
import { PiPersonArmsSpreadFill } from "react-icons/pi";
import { AiOutlineBarcode } from "react-icons/ai";
import { AiFillSmile } from "react-icons/ai";
import { AiFillStar } from "react-icons/ai";
import './nav.css'

import React, { useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import { useEffect } from 'react';

const NavElement = (props) => {
	const [status, setStatus] = useState(props.status);
	const location = useLocation();
	useEffect(() => {
		if(location.hash === props.link) setStatus("selected");
		else setStatus("none");
	}, [location, props.link]);
	return (
	<a href={props.link} onClick={() => {setStatus("selected")}} className={"navbar-item item-"+props.item} status={status}>
    		{ props.children }
	</a>
	);
}


const NavBar = (props) => {
	return (
		<nav className={"navbar"}>
			<NavElement item={1} link="#home">
				<RiHome5Fill size={"2rem"}/>
			</NavElement>
			<NavElement item={2} link="#shop">
				<FaBasketShopping size={"2rem"}/>
			</NavElement>
			<NavElement item={3} link="#star">
				<AiFillStar size={"2rem"}/>
			</NavElement>
			<NavElement item={4} link="#smile">
				<AiFillSmile size={"2rem"}/>
			</NavElement>
			<NavElement item={5} link="#barcode">
				<AiOutlineBarcode size={"2rem"}/>
			</NavElement>
			<NavElement item={6} link="#about">
				<PiPersonArmsSpreadFill size={"2rem"}/>
			</NavElement>
			<div className="navbar-item navbar-item-bg"></div>
		</nav>
	)
}

export {NavBar}