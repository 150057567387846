import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { FaCartShopping } from "react-icons/fa6";
import { AiFillSmile } from "react-icons/ai";


import { NavBar } from './components/nav.jsx';
import './common/global.css';
import './common/fonts.css';
import './common/poppins.css';

import { VarPage, Page } from './components/structural.jsx';

import './pages/home.css';
import './pages/shop.css';
import './pages/star.css';
import './pages/smile.css';
import './pages/barcode.css';
import './pages/about.css';

function App() {
  return (
    <>
      <BrowserRouter>        
        <Page id="home" className="home">
          <h1 className="heading">SNEUSE.ORG</h1>
          <img className="kangaroo" src={process.env.PUBLIC_URL + "/img/kangaroo.svg"} alt="kangoroo" draggable="false" />
        </Page>

        <Page id="shop" className="shop">
          <h1 className="heading">Shop</h1>
          <div className="container-1">
            <div className="subcontainer subcontainer-1">
              <h1 className="name">SNEUSE.ORG MUG</h1>
              <div className="properties">
                <h3 className="heading">Product Details</h3>
                <div></div>
                <span className="property-name">Title</span>
                <span className="property-value">SNEUSE.ORG MUG</span>

                <span className="property-name">Type</span>
                <span className="property-value">mug</span>

                <span className="property-name">Size</span>
                <span className="property-value">420ml</span>

                <span className="property-name">Details</span>
                <span className="property-value">made with love</span>

                <span className="property-name">Manufacturer</span>
                <span className="property-value">SNEUSE.ORG INCORPORATED</span>

                <span className="property-name">Price</span>
                <span className="property-value">69.99 €</span>
              </div>
              <div className="btn-container">
                <button className="order-btn"><FaCartShopping size={"2rem"}/><span>Add to cart</span></button>
              </div>
            </div>
            <div className="subcontainer subcontainer-2">
              <div className="subcontainer-bg"></div>
              <img className="mug" src={process.env.PUBLIC_URL + "/img/mug3.png"} alt="mug" draggable="false" />
            </div>
          </div>
        </Page>
        
        <Page id="star" className="star">
          <div className="star-mission">
            <div>
              <h1>Our Mission</h1>
              <p>To empower as many sneuses and kangaroos as there are in this world!</p>
            </div>
            <div>
              <img className="scooter" src={process.env.PUBLIC_URL + "/img/scooter.png"} alt="scooter" draggable="false" />
            </div>
          </div>
        </Page>
        
        <Page id="smile" className="smile">
          <div className="smile-container">
            <div>
              <img className="communicate" src={process.env.PUBLIC_URL + "/img/communicate.png"} alt="communicate" draggable="false" />
            </div>
            <div>
              <h1>What we do</h1>
              <p>We at SNEUSE.ORG communicate with sneuses around the world everyday to ensure we reach our goal</p>
            </div>
          </div>
        </Page>
        
        <Page id="barcode" className="barcode">
          <h1>Statistics</h1>
          <div className="stats-container">
            <div className="stat-property">
              <span className="stat-name">Name</span>
              <span className="stat-value">Sneuse</span>
            </div>
            <div className="stat-property">
              <span className="stat-name">Distance travelled</span>
              <span className="stat-value">42.069,42 km</span>
            </div>
            <div className="stat-property">
              <span className="stat-name">Traded with villagers</span>
              <span className="stat-value">2</span>
            </div>
            <div className="stat-property">
              <span className="stat-name">Times Slept in a Bed</span>
              <span className="stat-value">9490</span>
            </div>
            <div className="stat-property">
              <span className="stat-name">Jumps</span>
              <span className="stat-value">24923</span>
            </div>
            <div className="stat-property">
              <span className="stat-name">Number of deaths</span>
              <span className="stat-value">0</span>
            </div>
            <div className="stat-property">
              <span className="stat-name">Distance by pig</span>
              <span className="stat-value">6.902,30 km</span>
            </div>
            <div className="stat-property">
              <span className="stat-name">Mob kills</span>
              <span className="stat-value">3</span>
            </div>
            <div className="stat-property">
              <span className="stat-name">Distance Walked on Water </span>
              <span className="stat-value">17 m</span>
            </div>
          </div>
        </Page>
        
        <Page id="about" className="about">
          <div className="about-container">
            <div>
              <h1>About</h1>
              <p>SNEUSE.ORG INCORPORATED is a company based in Australia. SNEUSE.ORG INCORPORATED offers a large selection of educational programs, such as jumping classes and more.</p>
            </div>
            <div>
              <img className="superwoman" src={process.env.PUBLIC_URL + "/img/superwoman.png"} alt="superwoman" draggable="false" />
            </div>
          </div>
        </Page>

          <NavBar/>


      </BrowserRouter>
    </>
  );
}

export default App;
